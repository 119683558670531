
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {}
})
class VText extends Vue {
  
  @Prop({required: true})
  private text!: string;
  
  @Prop({default: 1})
  private size!: number;
  
  private get firstLetter(): string {
    if (this.text.length > 0) {
      return this.text.charAt(0);
    }
    return "";
  }
  
  private get restOfText(): string {
    if (this.text.length > 1) {
      return this.text.substring(1);
    }
    return "";
  }
  
  private get firstSize() {
    return (this.size + 0.2) + 'rem';
  }
  
  private get restSize() {
    return this.size + 'rem';
  }
}

const __default__ = VText
import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "400730b3": (_vm.restSize),
  "385a1bd3": (_vm.firstSize)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__